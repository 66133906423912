@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500&display=swap");

h4 {
  margin: 0;
  font-size: 16px;
  font-weight: 400;
}

ion-card {
  margin: 10px;
  background: #ffffff !important;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05) !important;
  border-radius: 4px;
}

ion-card-header {
  padding: 10px 0;
}

ion-item-divider {
  background: transparent;
  border: none;
  margin: 0;
}

ion-item:last-child {
  --inner-border-width: 0;
}

ion-icon {
  color: #2e67a0;
  margin-right: 5px;
}

ion-button {
  margin-top: 0 !important;
  border-width: 1px;
}

ion-chip {
  --background: rgba(241, 241, 241, 0.815) !important;
}

.card-section ion-card {
  box-shadow: none;
}

.card-section ion-label {
  margin-bottom: 0 !important;
  font-weight: normal !important;
  font-family: Inter;
  font-style: normal;
  font-size: 14px;
  line-height: 22px;
  color: #a0a0a0;
}

.card-section h3 {
  margin: 5px 0 !important;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  font-family: "Inter", sans-serif;
}

.card-section h4 {
  font-style: normal;
  line-height: 22px;
  color: #000000;
  opacity: 0.65;
  font-size: 14px !important;
  font-weight: normal !important;
  margin: 5px 0 !important;
  font-family: "Inter", sans-serif;
}

.meta-data {
  color: #000000;
  opacity: 0.45;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
}

.open-close {
  width: 100%;
  height: 30px;
  padding: 5px;
  background: red;
}

.detail-address {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  color: #000000;
  opacity: 0.75;
}

.distance {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  color: #28639d;
  background: #d6e6f5;
  border-radius: 14px;
  padding: 5px;
}

.service-icons {
  display: flex;
  justify-content: center;
}

.service-icons img {
  margin-right: 5px;
}

.header-actions {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  align-content: flex-start;
}

.header-icon {
  margin: 0 10px;
}

.header-date {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  color: #000000;
  opacity: 0.65;
  margin-bottom: 0;
}

.header-time {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  opacity: 0.85;
  margin-top: -5px;
}

.header-btn {
  margin-left: auto;
}

.cancel-form {
  padding: 10px;
}

.note-actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.send-btn {
  margin-top: 5px;
}

ion-textarea {
  font-size: 0.9rem !important;
}

.notes > .item-native {
  background: black;
}

/* .modal-wrapper {
  height: 20% !important;
  bottom: 0;
  position: absolute;
} */

.modal-title {
  display: flex;
  align-items: center;
}

.edit-note {
  padding: 30px 10px 20px 10px;
}

.edit-note ion-textarea {
  background: #f3f7ff;
  margin-bottom: 20px;
  border-radius: 5px;
}

.incl-services {
  border-bottom: dashed 1px #ddd;
}

.incl-services last-child {
  border: none;
}

@media (prefers-color-scheme: dark) {
  ion-chip {
    --background: rgba(241, 241, 241, 0.26) !important;
  }

  .card-section h4 {
    color: #ffffff;
    opacity: 0.85;
  }

  .meta-data {
    color: #ffffff;
    opacity: 0.65;
  }

  .header-date {
    color: #ffffff;
    opacity: 0.75;
  }
  .header-time {
    color: #ffffff;
    opacity: 0.65;
  }

  .detail-address {
    color: #ffffff;
    opacity: 0.75;
  }
}

.currentNote-text {
  display: block;
}

.currentNote {
  border-bottom: 1px solid #ddd;
  padding: 20px;
}

.customImageGallery {
  display: -ms-grid;
  display: grid;
  /* max-width: 90%; */
  grid-template-columns: repeat(auto-fill, 90px);
  gap: 20px;
  margin-top: 10px;
}

.customImageGallery .imageWithClose {
  position: relative;
  text-align: right;
  max-height: 75px;
  max-width: 75px;
}

.customImageGallery .imageWithClose.addImage {
  max-height: 105px;
  max-width: 105px;
}

.amenityInfo {
  padding-left: 10px;
  font-size: 13px;
}
.amenityAddress {
  font-size: 13px;
}

.customImageGallery .imageWithClose .close {
  position: absolute;
  right: -15px;
  background-color: #f3f7ff;
  bottom: -5px;
  font-size: 25px;
  padding: 0;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 1;
}

.customImageGallery .imageWithClose .close .hydrated {
  margin: 0;
  color: red;
}

.customImageGallery .imageWithClose .view {
  position: absolute;
  padding: 0;
  height: 100%;
  width: 100%;
  left: 0;
  background-color: rgba(0, 0, 0, 0.25);
  opacity: 0;
  transition: 0.3s;
}

.customImageGallery .imageWithClose .view:hover {
  opacity: 1;
}

.customImageGallery .imageWithClose .view .hydrated {
  margin: 0;
  color: #fff;
  font-size: 24px;
}

.customImageGallery img {
  width: 75px;
  height: 75px;
  -o-object-fit: cover;
  object-fit: cover;
}

.imagedelete-confirm {
  padding: 15px 15px 5px;
  text-align: center;
}

.imagedelete-confirm p {
  margin-top: 0;
}

.selectedImage.customImageGallery {
  margin: 15px 0;
  width: 100%;
}

.imagePreview .modal-wrapper {
  max-height: 80vh;
  background: transparent;
  box-shadow: none;
  margin: 15px;
}

.imagePreview .modal-wrapper .ion-page {
  justify-content: center;
  align-items: center;
}

.imagePreview .modal-wrapper .ion-page .button {
  margin-top: 15px !important;
  margin-left: auto;
  margin-right: auto;
  max-width: 85px;
  display: block;
  /* border-radius: 2px; */
}

.load-more-button {
  text-align: center;
  margin: 15px 15px 15px;
}
.load-more-button button {
  max-width: 175px;
  border: 1px solid #ddd;
  padding: 12px 20px;
  background-color: #fff;
  color: #3880ff;
  border-radius: 3px;
  font-size: 14px;
  transition: 0.3s;
}
.load-more-button button:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
