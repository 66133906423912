.icon-detail {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  margin: 7px;
}

.icon {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  margin-right: 10px;
}

.detail {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  opacity: 0.65;
}

.ion-textarea {
  background: #f3f7ff !important;
  margin-bottom: 20px;
  border-radius: 5px !important;
}

@media (prefers-color-scheme: dark) {
  .detail {
    color: #ffffff;
    opacity: 0.65;
  }
}
