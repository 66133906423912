.empty-state {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.empty-state_image {
  font-size: 4rem;
}

.empty-state_text {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.24px;
  color: #aeccea;
}
