.profile-pay ion-grid {
  --ion-grid-column-padding: 0 !important;
}

.pay-title,
.calendar-nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px;
  align-items: center;
  width: 100%;
}

.pay-day {
  color: #1079c6;
  font-size: 15px;
  font-weight: 700;
  padding: 4px 0;
}

.pay-amount {
  color: #105801;
  background: #e7fae3;
  font-size: 15px;
  font-weight: 700;
  padding: 4px 6px;
  border-radius: 12px;
}

.pay-content-title {
  color: #1e1e1e;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 0;
  padding-left: 10px;
}

.pay-content .label {
  font-size: 14px;
}

.calendar-nav .pay-week {
  color: #1079c6;
  font-size: 15px;
  font-weight: 700;
  padding: 4px 0;
}

.week-total {
  color: #333333;
  font-size: 18px;
  font-weight: 700;
}

.pay-hsn {
  color: #333333;
}

@media (prefers-color-scheme: dark) {
  .calendar-nav .pay-week,
  .pay-day {
    color: #84b4d6;
  }

  .week-total {
    color: #ffffff;
    font-size: 18px;
    font-weight: 700;
  }

  .pay-content-title {
    color: #ffffff;
  }

  .pay-hsn {
    color: aliceblue;
  }
}
