.contact-template-textarea {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 5px;
}

@media (prefers-color-scheme: dark) {
  .contact-template-textarea {
    background: #282f32;
    border: 1px solid #282f32;
    color: #fff;
  }
}
