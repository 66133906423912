#favorites h2 {
    margin-top: 0;
    margin-bottom: 0.5em;
    font-size: 1.2rem;
}

#favorites p {
    margin-top: 0;
    margin-bottom: 1em;
    font-size: 0.8rem;
}