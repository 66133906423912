.label-detail {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  margin: 7px;
}

.label {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #000000;
  opacity: 0.45;
}

.detail {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-align: right;
  color: #000000;
  opacity: 0.65;
}

@media (prefers-color-scheme: dark) {
  .detail {
    color: #ffffff;
    opacity: 0.85;
  }

  .label {
    color: #ffffff;
    opacity: 0.65;
  }
}
