@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500&display=swap);
/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #2e67a0;
  --ion-color-primary-rgb: 16, 121, 198;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #184777;
  --ion-color-primary-tint: #4477aa;

  /** secondary **/
  --ion-color-secondary: #04a4e8;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #059925;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #09a748;
  --ion-color-success-tint: #13d461;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** custom **/
  /* --ion-background-color: #e1e2e6; */
}

ion-card {
  --background: #ffffff !important;
  --box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05) !important;
  --border-radius: 4px;
}

ion-content {
  --background: #f3f7ff;
}

@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  body {
    --ion-color-primary: #9fc9e8;
    --ion-color-primary-rgb: 159, 201, 232;
    --ion-color-primary-contrast: #000000;
    --ion-color-primary-contrast-rgb: 0, 0, 0;
    --ion-color-primary-shade: #8cb1cc;
    --ion-color-primary-tint: #a9ceea;

    --ion-color-secondary: #50c8ff;
    --ion-color-secondary-rgb: 80, 200, 255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106, 100, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47, 223, 117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255, 213, 52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255, 73, 97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244, 245, 248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0, 0, 0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34, 36, 40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255, 255, 255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0, 0, 0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-toolbar-background: #0d0d0d;

    --ion-item-background: #000000;
  }

  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18, 18, 18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;
  }

  ion-card {
    --background: rgb(31, 31, 31) !important;
  }

  ion-content {
    --background: #333333;
  }
}

* {
  -webkit-user-select: text !important;
  user-select: text !important;
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.flex-align-center {
  display: flex;
  align-items: center;
}
.login-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-image: url(/static/media/backyard.b87b4136.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  height: 100%;
  background-color: #ffffff;
}

.login-wrapper > .logo {
  margin: 30px;
  align-self: center;
}

.input-group {
  padding: 10px;
}

.actions {
  margin: 30px 0;
  text-align: center;
}

.actions p {
  font-size: 14px;
  color: #28639d;
}

.social-buttons {
  margin-top: 40px;
}

.login-card {
  border-radius: 4px;
  margin-left: 10px;
  margin-right: 10px;
  background: rgba(255, 255, 255, 0.6);
  width: 90%;
}

.login-wrapper ion-input {
  --background: transparent;
  --color: #000000 !important;
  --placeholder-color: #202020 !important;
}
.login-wrapper ion-item {
  --border-color: #7a7a7a;
  --background: transparent;
}

.login-wrapper ion-list {
  --background: #ffffff !important;
}

.login-wrapper ion-button {
  --background: #1079c6 !important;
  --background-activated: #1079c6 !important;
  --box-shadow: none !important;
}

.registerBtn {
  margin: 30px 10px 10px !important;
}
.register-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-image: url(/static/media/frontyard.5a6126ae.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  height: 100%;
  background-color: #ffffff;
}

.register-wrapper > .logo {
  margin: 30px;
  align-self: center;
}

.register-card {
  border-radius: 4px;
  margin-left: 10px;
  margin-right: 10px;
  background: rgba(255, 255, 255, 0.6);
  width: 90%;
}

.register-wrapper ion-input {
  --background: transparent;
  --color: #000000 !important;
  --placeholder-color: #202020 !important;
}
.register-wrapper ion-item {
  --border-color: #7a7a7a;
  --background: transparent;
}

.register-wrapper ion-list {
  --background: #ffffff !important;
}

.register-wrapper ion-button {
  --background: #1079c6 !important;
  --background-activated: #1079c6 !important;
  --box-shadow: none !important;
}

h4 {
  margin: 0;
  font-size: 16px;
  font-weight: 400;
}

ion-card {
  margin: 10px;
  background: #ffffff !important;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05) !important;
  border-radius: 4px;
}

ion-card-header {
  padding: 10px 0;
}

ion-item-divider {
  background: transparent;
  border: none;
  margin: 0;
}

ion-item:last-child {
  --inner-border-width: 0;
}

ion-icon {
  color: #2e67a0;
  margin-right: 5px;
}

ion-button {
  margin-top: 0 !important;
  border-width: 1px;
}

ion-chip {
  --background: rgba(241, 241, 241, 0.815) !important;
}

.card-section ion-card {
  box-shadow: none;
}

.card-section ion-label {
  margin-bottom: 0 !important;
  font-weight: normal !important;
  font-family: Inter;
  font-style: normal;
  font-size: 14px;
  line-height: 22px;
  color: #a0a0a0;
}

.card-section h3 {
  margin: 5px 0 !important;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  font-family: "Inter", sans-serif;
}

.card-section h4 {
  font-style: normal;
  line-height: 22px;
  color: #000000;
  opacity: 0.65;
  font-size: 14px !important;
  font-weight: normal !important;
  margin: 5px 0 !important;
  font-family: "Inter", sans-serif;
}

.meta-data {
  color: #000000;
  opacity: 0.45;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
}

.open-close {
  width: 100%;
  height: 30px;
  padding: 5px;
  background: red;
}

.detail-address {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  color: #000000;
  opacity: 0.75;
}

.distance {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  color: #28639d;
  background: #d6e6f5;
  border-radius: 14px;
  padding: 5px;
}

.service-icons {
  display: flex;
  justify-content: center;
}

.service-icons img {
  margin-right: 5px;
}

.header-actions {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  align-content: flex-start;
}

.header-icon {
  margin: 0 10px;
}

.header-date {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  color: #000000;
  opacity: 0.65;
  margin-bottom: 0;
}

.header-time {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  opacity: 0.85;
  margin-top: -5px;
}

.header-btn {
  margin-left: auto;
}

.cancel-form {
  padding: 10px;
}

.note-actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.send-btn {
  margin-top: 5px;
}

ion-textarea {
  font-size: 0.9rem !important;
}

.notes > .item-native {
  background: black;
}

/* .modal-wrapper {
  height: 20% !important;
  bottom: 0;
  position: absolute;
} */

.modal-title {
  display: flex;
  align-items: center;
}

.edit-note {
  padding: 30px 10px 20px 10px;
}

.edit-note ion-textarea {
  background: #f3f7ff;
  margin-bottom: 20px;
  border-radius: 5px;
}

.incl-services {
  border-bottom: dashed 1px #ddd;
}

.incl-services last-child {
  border: none;
}

@media (prefers-color-scheme: dark) {
  ion-chip {
    --background: rgba(241, 241, 241, 0.26) !important;
  }

  .card-section h4 {
    color: #ffffff;
    opacity: 0.85;
  }

  .meta-data {
    color: #ffffff;
    opacity: 0.65;
  }

  .header-date {
    color: #ffffff;
    opacity: 0.75;
  }
  .header-time {
    color: #ffffff;
    opacity: 0.65;
  }

  .detail-address {
    color: #ffffff;
    opacity: 0.75;
  }
}

.currentNote-text {
  display: block;
}

.currentNote {
  border-bottom: 1px solid #ddd;
  padding: 20px;
}

.customImageGallery {
  display: grid;
  /* max-width: 90%; */
  grid-template-columns: repeat(auto-fill, 90px);
  grid-gap: 20px;
  gap: 20px;
  margin-top: 10px;
}

.customImageGallery .imageWithClose {
  position: relative;
  text-align: right;
  max-height: 75px;
  max-width: 75px;
}

.customImageGallery .imageWithClose.addImage {
  max-height: 105px;
  max-width: 105px;
}

.amenityInfo {
  padding-left: 10px;
  font-size: 13px;
}
.amenityAddress {
  font-size: 13px;
}

.customImageGallery .imageWithClose .close {
  position: absolute;
  right: -15px;
  background-color: #f3f7ff;
  bottom: -5px;
  font-size: 25px;
  padding: 0;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.customImageGallery .imageWithClose .close .hydrated {
  margin: 0;
  color: red;
}

.customImageGallery .imageWithClose .view {
  position: absolute;
  padding: 0;
  height: 100%;
  width: 100%;
  left: 0;
  background-color: rgba(0, 0, 0, 0.25);
  opacity: 0;
  transition: 0.3s;
}

.customImageGallery .imageWithClose .view:hover {
  opacity: 1;
}

.customImageGallery .imageWithClose .view .hydrated {
  margin: 0;
  color: #fff;
  font-size: 24px;
}

.customImageGallery img {
  width: 75px;
  height: 75px;
  object-fit: cover;
}

.imagedelete-confirm {
  padding: 15px 15px 5px;
  text-align: center;
}

.imagedelete-confirm p {
  margin-top: 0;
}

.selectedImage.customImageGallery {
  margin: 15px 0;
  width: 100%;
}

.imagePreview .modal-wrapper {
  max-height: 80vh;
  background: transparent;
  box-shadow: none;
  margin: 15px;
}

.imagePreview .modal-wrapper .ion-page {
  justify-content: center;
  align-items: center;
}

.imagePreview .modal-wrapper .ion-page .button {
  margin-top: 15px !important;
  margin-left: auto;
  margin-right: auto;
  max-width: 85px;
  display: block;
  /* border-radius: 2px; */
}

.load-more-button {
  text-align: center;
  margin: 15px 15px 15px;
}
.load-more-button button {
  max-width: 175px;
  border: 1px solid #ddd;
  padding: 12px 20px;
  background-color: #fff;
  color: #3880ff;
  border-radius: 3px;
  font-size: 14px;
  transition: 0.3s;
}
.load-more-button button:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.logo {
  max-width: 200px;
  margin-top: 0;
}

.logo img {
  width: 100%;
}

.collapse-header {
  background-color: #eef6fd;
  width: 100%;
}

.ion-align-items-end {
  text-align: right;
}

.ion-align-items-center {
  text-align: center;
  margin: 10px;
}

/* .page-tabs {
  background-color: #ffffff;
} */

.collapse-header ion-grid ion-col {
  padding: 0 !important;
}

.collapse-header h1 {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 22px;
  text-align: center;
  color: #000000;
  opacity: 0.85;
  margin: 0;
}

.profile-btn {
  width: 48px;
  height: 48px;
  float: right;
  margin-top: 10px;
}

ion-segment {
  width: 220px;
}

ion-segment-button {
  max-width: 40px;
  text-transform: capitalize;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  margin: 0 10px;
}

ion-segment-button.active {
  color: #28639d;
}

/* ion-toolbar {
  --background: transparent;
  --ion-color-base: transparent !important;
} */

@media (prefers-color-scheme: dark) {
  .collapse-header {
    background-color: #1f1f1f;
  }
}

.empty-state {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.empty-state_image {
  font-size: 4rem;
}

.empty-state_text {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.24px;
  color: #aeccea;
}

.icon-detail {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  margin: 7px;
}

.icon {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  margin-right: 10px;
}

.detail {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  opacity: 0.65;
}

.ion-textarea {
  background: #f3f7ff !important;
  margin-bottom: 20px;
  border-radius: 5px !important;
}

@media (prefers-color-scheme: dark) {
  .detail {
    color: #ffffff;
    opacity: 0.65;
  }
}

.profile ion-card {
  padding: 0 5px !important;
}

.profile-pic {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  align-content: center;
  margin: 20px;
}

.profile-pic h3 {
  margin: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #000000;
  opacity: 0.85;
}
.profile ion-item {
  font-size: 0.8rem;
  padding: 0;
}

.profile ion-icon {
  margin-right: 5px !important;
  font-size: 1rem;
  color: #798ec1;
}

.section-title {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  opacity: 0.85;
  border-bottom: 1px solid #d6e6f5;
  padding: 10px 0;
}

ion-button {
  margin-top: 20px;
}

.profile-actions {
  margin: 10px;
}

@media (prefers-color-scheme: dark) {
  ion-card {
    background-color: #414040 !important;
  }

  .section-title {
    color: #ffffff;
  }

  .user-name h3 {
    color: #ffffff;
    opacity: 0.85;
  }
}

.profile-pay ion-grid {
  --ion-grid-column-padding: 0 !important;
}

.pay-title,
.calendar-nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px;
  align-items: center;
  width: 100%;
}

.pay-day {
  color: #1079c6;
  font-size: 15px;
  font-weight: 700;
  padding: 4px 0;
}

.pay-amount {
  color: #105801;
  background: #e7fae3;
  font-size: 15px;
  font-weight: 700;
  padding: 4px 6px;
  border-radius: 12px;
}

.pay-content-title {
  color: #1e1e1e;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 0;
  padding-left: 10px;
}

.pay-content .label {
  font-size: 14px;
}

.calendar-nav .pay-week {
  color: #1079c6;
  font-size: 15px;
  font-weight: 700;
  padding: 4px 0;
}

.week-total {
  color: #333333;
  font-size: 18px;
  font-weight: 700;
}

.pay-hsn {
  color: #333333;
}

@media (prefers-color-scheme: dark) {
  .calendar-nav .pay-week,
  .pay-day {
    color: #84b4d6;
  }

  .week-total {
    color: #ffffff;
    font-size: 18px;
    font-weight: 700;
  }

  .pay-content-title {
    color: #ffffff;
  }

  .pay-hsn {
    color: aliceblue;
  }
}

.accordion-section {
  display: flex;
  flex-direction: column;
  margin: 10px 5px;
  border-radius: 4px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05) !important;
}

.accordion {
  background-color: #ffffff;
  color: #000;
  padding: 10px;
  display: flex;
  align-items: center;
  transition: background-color 0.4s ease;
}

.accordion:active {
  outline: none;
  border: none;
}

.accordion-title {
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 22px;
  color: #000000;
  opacity: 0.85;
}

.accordion-icon {
  margin-left: auto;
  transition: -webkit-transform 0.4s ease;
  transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease;
}

.rotate {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.accordion-content {
  background-color: #ffffff;
  overflow: hidden;
  transition: max-height 0.6s ease;
}

.accordion-content-divider {
  border-top: 1px solid #d6e6f5;
}

.prefix-icon {
  font-size: 18px;
  margin-right: 10px;
}

button {
  outline: none;
}

@media (prefers-color-scheme: dark) {
  .accordion {
    background: #282f32;
  }

  .accordion-content {
    background: #282f32;
  }

  .accordion-content-divider {
    border-top: 1px solid #5c7c9b;
  }

  .accordion-title {
    color: #ffffff;
    opacity: 0.85;
  }

  .accordion-section h4 {
    color: #ffffff;
  }
}

.label-detail {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  margin: 7px;
}

.label {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #000000;
  opacity: 0.45;
}

.detail {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-align: right;
  color: #000000;
  opacity: 0.65;
}

@media (prefers-color-scheme: dark) {
  .detail {
    color: #ffffff;
    opacity: 0.85;
  }

  .label {
    color: #ffffff;
    opacity: 0.65;
  }
}

.help-item-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #e0e0e0;
    width: 100%;
}

.commercial {
    color: #ffa4b6;
}

.residential {
    color: #a155b9;
}

.multifamily {
    color: #f765a3;
}

.key-item {
    margin-right: 5px;
    padding: 0 5px;
    
}



.help-item-wrapper:last-child {
    border-bottom: none;
}

/* .flex_item_left {
    width: 100px;
  }
  .flex_item_right {
    flex-direction: row;
    justify-content: space-between;
  } */

@media (prefers-color-scheme: dark) {
    .help-item-wrapper {
        border-bottom: 1px solid #333;
    }
  }
.note {
  padding: 5px 10px;
  margin-bottom: 5px;
}

.pinned {
  background-color: #f4fcf1;
}

.note-text-container {
  margin-bottom: 10px;
}
.note-author {
  font-size: 13px;
  color: #777777;
  margin-bottom: 10px;
  font-weight: 700;
  margin-right: 10px;
}

.note-metadata {
  font-size: 12px;
  color: #777777;
  font-style: italic;
  margin-bottom: 10px;
}

.note-text {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  color: #4b506d;
}

.unread {
  border-left: 4px solid tomato;
}

@media (prefers-color-scheme: dark) {
  .pinned {
    background-color: #cee7c5;
    color: #000;
  }
  .note-text {
    color: #000;
  }
  .pinned .note-text {
    color: #000;
  }
}

.contact-template-textarea {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 5px;
}

@media (prefers-color-scheme: dark) {
  .contact-template-textarea {
    background: #282f32;
    border: 1px solid #282f32;
    color: #fff;
  }
}

ion-modal#complete-modal {
    --width: fit-content;
    --min-width: 350px;
    --height: fit-content;
    --border-radius: 6px;
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  }
  
  ion-modal#complete-modal h1 {
    margin: 20px 20px 10px 20px;
  }
  
  ion-modal#complete-modal ion-icon {
    margin-right: 6px;
  
    width: 48px;
    height: 48px;
  
    padding: 4px 0;
  
    color: #aaaaaa;
  }
  
  ion-modal#complete-modal .action-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 40px 10px 20px 20px;
  }

  ion-modal#complete-modal .wrapper {
    margin: 10px;
    padding: 20px;
  }
.reset-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-image: url(/static/media/registerPageBg.4bbb578a.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  height: 100%;
  background-color: #ffffff;
}

.reset-wrapper > .logo {
  margin: 30px;
  align-self: center;
}

.reset-card {
  border-radius: 4px;
  margin-left: 10px;
  margin-right: 10px;
  background: rgba(255, 255, 255, 0.6);
  width: 90%;
}

.reset-wrapper ion-input {
  --background: transparent;
  --color: #000000 !important;
  --placeholder-color: #202020 !important;
}
.reset-wrapper ion-item {
  --border-color: #7a7a7a;
  --background: transparent;
}

.reset-wrapper ion-list {
  --background: #ffffff !important;
}

.reset-wrapper ion-button {
  --background: #1079c6 !important;
  --background-activated: #1079c6 !important;
  --box-shadow: none !important;
}

.reset-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-image: url(/static/media/registerPageBg.4bbb578a.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  height: 100%;
  background-color: #ffffff;
}

.reset-wrapper > .logo {
  margin: 30px;
  align-self: center;
}

.reset-card {
  border-radius: 4px;
  margin-left: 10px;
  margin-right: 10px;
  background: rgba(255, 255, 255, 0.6);
  width: 90%;
}

.reset-wrapper ion-input {
  --background: transparent;
  --color: #000000 !important;
  --placeholder-color: #202020 !important;
}
.reset-wrapper ion-item {
  --border-color: #7a7a7a;
  --background: transparent;
}

.reset-wrapper ion-list {
  --background: #ffffff !important;
}

.reset-wrapper ion-button {
  --background: #1079c6 !important;
  --background-activated: #1079c6 !important;
  --box-shadow: none !important;
}

#favorites h2 {
    margin-top: 0;
    margin-bottom: 0.5em;
    font-size: 1.2rem;
}

#favorites p {
    margin-top: 0;
    margin-bottom: 1em;
    font-size: 0.8rem;
}
