.note {
  padding: 5px 10px;
  margin-bottom: 5px;
}

.pinned {
  background-color: #f4fcf1;
}

.note-text-container {
  margin-bottom: 10px;
}
.note-author {
  font-size: 13px;
  color: #777777;
  margin-bottom: 10px;
  font-weight: 700;
  margin-right: 10px;
}

.note-metadata {
  font-size: 12px;
  color: #777777;
  font-style: italic;
  margin-bottom: 10px;
}

.note-text {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  color: #4b506d;
}

.unread {
  border-left: 4px solid tomato;
}

@media (prefers-color-scheme: dark) {
  .pinned {
    background-color: #cee7c5;
    color: #000;
  }
  .note-text {
    color: #000;
  }
  .pinned .note-text {
    color: #000;
  }
}
