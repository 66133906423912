* {
  -webkit-user-select: text !important;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text !important;
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.flex-align-center {
  display: flex;
  align-items: center;
}