.accordion-section {
  display: flex;
  flex-direction: column;
  margin: 10px 5px;
  border-radius: 4px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05) !important;
}

.accordion {
  background-color: #ffffff;
  color: #000;
  padding: 10px;
  display: flex;
  align-items: center;
  transition: background-color 0.4s ease;
}

.accordion:active {
  outline: none;
  border: none;
}

.accordion-title {
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 22px;
  color: #000000;
  opacity: 0.85;
}

.accordion-icon {
  margin-left: auto;
  transition: transform 0.4s ease;
}

.rotate {
  transform: rotate(180deg);
}

.accordion-content {
  background-color: #ffffff;
  overflow: hidden;
  transition: max-height 0.6s ease;
}

.accordion-content-divider {
  border-top: 1px solid #d6e6f5;
}

.prefix-icon {
  font-size: 18px;
  margin-right: 10px;
}

button {
  outline: none;
}

@media (prefers-color-scheme: dark) {
  .accordion {
    background: #282f32;
  }

  .accordion-content {
    background: #282f32;
  }

  .accordion-content-divider {
    border-top: 1px solid #5c7c9b;
  }

  .accordion-title {
    color: #ffffff;
    opacity: 0.85;
  }

  .accordion-section h4 {
    color: #ffffff;
  }
}
