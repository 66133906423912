.profile ion-card {
  padding: 0 5px !important;
}

.profile-pic {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  align-content: center;
  margin: 20px;
}

.profile-pic h3 {
  margin: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #000000;
  opacity: 0.85;
}
.profile ion-item {
  font-size: 0.8rem;
  padding: 0;
}

.profile ion-icon {
  margin-right: 5px !important;
  font-size: 1rem;
  color: #798ec1;
}

.section-title {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  opacity: 0.85;
  border-bottom: 1px solid #d6e6f5;
  padding: 10px 0;
}

ion-button {
  margin-top: 20px;
}

.profile-actions {
  margin: 10px;
}

@media (prefers-color-scheme: dark) {
  ion-card {
    background-color: #414040 !important;
  }

  .section-title {
    color: #ffffff;
  }

  .user-name h3 {
    color: #ffffff;
    opacity: 0.85;
  }
}
