.login-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-image: url("../assets/images/backyard.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  height: 100%;
  background-color: #ffffff;
}

.login-wrapper > .logo {
  margin: 30px;
  align-self: center;
}

.input-group {
  padding: 10px;
}

.actions {
  margin: 30px 0;
  text-align: center;
}

.actions p {
  font-size: 14px;
  color: #28639d;
}

.social-buttons {
  margin-top: 40px;
}

.login-card {
  border-radius: 4px;
  margin-left: 10px;
  margin-right: 10px;
  background: rgba(255, 255, 255, 0.6);
  width: 90%;
}

.login-wrapper ion-input {
  --background: transparent;
  --color: #000000 !important;
  --placeholder-color: #202020 !important;
}
.login-wrapper ion-item {
  --border-color: #7a7a7a;
  --background: transparent;
}

.login-wrapper ion-list {
  --background: #ffffff !important;
}

.login-wrapper ion-button {
  --background: #1079c6 !important;
  --background-activated: #1079c6 !important;
  --box-shadow: none !important;
}
