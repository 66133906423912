.help-item-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #e0e0e0;
    width: 100%;
}

.commercial {
    color: #ffa4b6;
}

.residential {
    color: #a155b9;
}

.multifamily {
    color: #f765a3;
}

.key-item {
    margin-right: 5px;
    padding: 0 5px;
    
}



.help-item-wrapper:last-child {
    border-bottom: none;
}

/* .flex_item_left {
    width: 100px;
  }
  .flex_item_right {
    flex-direction: row;
    justify-content: space-between;
  } */

@media (prefers-color-scheme: dark) {
    .help-item-wrapper {
        border-bottom: 1px solid #333;
    }
  }