ion-modal#complete-modal {
    --width: fit-content;
    --min-width: 350px;
    --height: fit-content;
    --border-radius: 6px;
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  }
  
  ion-modal#complete-modal h1 {
    margin: 20px 20px 10px 20px;
  }
  
  ion-modal#complete-modal ion-icon {
    margin-right: 6px;
  
    width: 48px;
    height: 48px;
  
    padding: 4px 0;
  
    color: #aaaaaa;
  }
  
  ion-modal#complete-modal .action-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 40px 10px 20px 20px;
  }

  ion-modal#complete-modal .wrapper {
    margin: 10px;
    padding: 20px;
  }